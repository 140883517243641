const React = require('react')
const { ContextProvider } = require('./src/context')

const style = document.documentElement.style

// Keep track of various heights and widths
const sizeListener = () => {
  style.setProperty('--window-height', window.innerHeight + 'px')
  style.setProperty('--window-width', window.innerWidth + 'px')
}
sizeListener()
window.addEventListener('resize', sizeListener)

exports.wrapRootElement = ({ element }) => <ContextProvider>{element}</ContextProvider>
