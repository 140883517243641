import React, { createContext, useState } from 'react'
// import { graphql, useStaticQuery } from 'gatsby'

const Context = createContext(null)

const ContextProvider = ({ children }) => {
  const [scroll, setScroll] = useState(0)
  const [scrolled, setScrolled] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [form, setForm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [mouseWheel, setMouseWheel] = useState(0)
  const [fund, setFund] = useState(0)

  return (
    <Context.Provider value={{ scroll, setScroll, scrolled, setScrolled, menuOpen, setMenuOpen, form, setForm, showForm, setShowForm, mouseWheel, setMouseWheel, fund, setFund }}>
      {children}
    </Context.Provider>
  )
}

export { Context, ContextProvider }
